<template>
    <div class="organizer" v-if="translates && translates[langUrl]">
        <div v-if="user" class="organizer__main-container">
            <div class="organizer__photo" :style="`background-image: url(${ imageSrc(user.avatar) })`"></div>
            <div class="organizer__text-container">
                <div class="organizer__name">{{ user.firstname }} {{ user.lastname }}</div>
                <!-- <div class="organizer__from" v-if="user.createdAt">{{ translates[langUrl].registered_since[lang] }}{{ user.createdAt | moment('YYYY') }}</div> -->
                <!-- <div class="organizer__confirmation">
                    <img src="../../../assets/ShieldTick.svg" style="margin-right: 2px" />
                    Личность подтверждена
                </div> -->
                <!-- <div class="organizer__confirmation">
                    4,5
                    <img src="../../../assets/StarAlone.svg" style="margin: 0 4px" />
                    (677)
                </div> -->
                <div v-if="user.about && user.about !== ''" class="organizer__is-desktop">
                    <div class="organizer__description" :class="{ description_hide: hide }"> {{ user.about }} </div>
                    <div
                        v-if="user.about && user.about !== '' && user.about.length > 300"
                        class="organizer__description__button"
                        @click="hide = !hide">{{ hide ? `${translates[langUrl].button_show[lang]}` : `${translates[langUrl].button_hide[lang]}` }}</div>
                </div>
            </div>
            <div class="organizer__personal-info">
                <div class="organizer__personal-info__name-and-from">
                    <div class="organizer__name">{{ user.firstname }} {{ user.lastname }}</div>
                    <div class="organizer__from" v-if="user.createdAt">{{ translates[langUrl].registered_since[lang] }} {{ user.createdAt | moment('YYYY') }}</div>
                </div>
                <!-- <div class="organizer__confirmation">
                4,5
                <img src="../../../assets/StarAlone.svg" style="margin: 0 4px" />
                (677)
                </div> -->
                <div class="organizer__confirmation">
                    <img src="../../../assets/ShieldTick.svg" style="margin-right: 2px" />
                    {{ translates[langUrl].confirmed[lang] }}
                </div>
            </div>
        </div>
        <div v-if="user && user.about && user.about !== ''" class="organizer__is-mobile">
            <div class="organizer__description" :class="{ description_hide: hide }"> {{ user.about }} </div>
            <div
                v-if="user.about && user.about !== '' && user.about.length > 90"
                class="organizer__description__button"
                @click="hide = !hide">{{ hide ? `${translates[langUrl].button_show[lang]}` : `${translates[langUrl].button_hide[lang]}` }}</div>
        </div>
        <BaseButton class="organizer__button" @click="openPopup()">{{ translates[langUrl].writeToExpert[lang] }}</BaseButton>
    </div>
</template>

<script>
    import { imageSrc } from '@/helpers';
    import BaseButton from '../BaseButton.vue';
    import { mapState } from 'vuex';

    export default {
        name: 'Organizer',
        components: {
            BaseButton
        },
        props: {
            user: {
                type: Object, 
                default: () => {}
            }
        },
        data: () => ({
            imageSrc,
            hide: true,
            langUrl: 'organizer'
        }),
        computed: {
            ...mapState('settings', ['lang']),
            ...mapState('translates', {
                translates: state => state.entities
            }),
        },
        methods: {
            openPopup() {
                this.$emit('openPopup',  `${ this.translates[this.langUrl].writeToExpert[this.lang] }`);
            }
        }
    }
</script>

<style lang="scss">
.organizer {
    display: flex;
    flex-direction: column;
    margin-bottom: 24px;
    &__main-container {
        display: flex;
        flex-direction: row;
        margin-top: 15px;

    }
    &__photo {
        width: 160px;
        height: 160px;
        border-radius: 10px;
        background-position: center;
        background-size: cover !important;
        @media all and (min-width: 768px) {
            min-width: 320px;
            width: 320px;
            height: 320px;
            border-radius: 10px;
        }
    }
    &__text-container {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        margin-left: 18px;
    }
    &__personal-info {
        display: none;
    }
    &__name {
        font-weight: 600;
        font-size: 16px;
        line-height: 19px;
        letter-spacing: -0.02em;
        color: #3A4256;
        margin-bottom: 4px;
    }
    &__confirmation {
        display: flex;
        flex-direction: row;
        font-weight: normal;
        font-size: 12px;
        line-height: 15px;
        letter-spacing: -0.02em;
        color: #3A4256;
        margin-top: 8px;
    }
    &__is-desktop {
        @media all and (max-width: 768px) {
            display: none;
        }
    }
    &__is-mobile {
        @media all and (min-width: 768px) {
            display: none;
        }
    }
    &__description {
        font-weight: normal;
        font-size: 14px;
        line-height: 150%;
        letter-spacing: -0.02em;
        color: #3A4256;
        margin-top: 12px;
        -webkit-line-clamp: 50;
        @media all and (min-width: 768px) {
            font-size: 16px;
            line-height: 150%;
        }
        &__button {
            color: #DA0A63;
            margin-top: 5px;
            cursor: pointer;
        }
        &.description_hide {
            max-height: 45px;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 4;
            -webkit-box-orient: vertical;
            @media all and (min-width: 768px) {
                max-height: 148px;
            }
        }
    }
    &__button {
        display: none;
    }
    &.detailHost {
        width: 800px;
        padding: 30px 40px;
        background: #FFFFFF;
        box-shadow: 0px 4px 22px rgba(44, 47, 56, 0.04);
        border-radius: 10px;
        align-items: flex-start;
        .organizer__photo {
            width: 110px;
            min-width: 110px;
            height: 110px;
            border-radius: 50%;
            background-position: center;
            background-size: cover !important;
        }
        .organizer__is-desktop {
            display: none;
        }
        .organizer__is-mobile {
            display: flex;
            flex-direction: column;
        }
        .organizer__button {
            display: flex;
            align-self: flex-start;
            margin-top: 24px;
        }
        .organizer__text-container {
            display: none;
        }
        .organizer__personal-info {
            display: flex;
            flex-direction: column;
            margin-left: 30px;
            justify-content: center;
            &__name-and-from {
                display: flex;
                flex-direction: row;
            }
            .organizer__from {
                font-weight: 400;
                font-size: 14px;
                line-height: 150%;
                letter-spacing: -0.02em;
                color: #93A3B4;
                margin-left: 10px;
            }
        }
    }
}
</style>