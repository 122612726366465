<template>
    <div class="download-form">
        <div v-if="!formSended">
            <h1 class="download-form__page-name">Пожалуйста, заполните форму, получите маршрут и подробную презентацию тура</h1>
            <div class="download-form__close" @click="close"></div>
            <ValidationObserver ref="validator" slim>
                <ValidationProvider v-slot="{ errors }" rules="required">
                    <BaseTextField
                        v-model="feedback.name"
                        placeholder="Ваше имя"
                        :error-messages="errors[0]"
                    />
                </ValidationProvider>
                <ValidationProvider v-slot="{ errors }" rules="required">
                    <!-- <BaseTextField
                        v-model="feedback.phone"
                        placeholder="Телефон"
                        type="tel"
                        autocomplete="off"
                        :error-messages="errors[0]"
                    /> -->
                    <BasePhoneNumberInput
                        v-model="feedback.phone"
                        :error-messages="errors[0]"
                        @update="number($event)"
                        @keyup.enter="login"
                    />
                </ValidationProvider>
                <BaseTextField
                    v-model="feedback.email"
                    placeholder="Ваша почта"
                />
                <BaseButton
                    class="button-fill download-form__save"
                    @click="save()">Получить программу тура</BaseButton>
                <div class="download-form__personal-info">
                    Нажимаю кнопку подтверждаю согласие c Пользовательским соглашением и Политикой конфиденциальности
                </div>
            </ValidationObserver>
        </div>
        <div v-if="formSended && trip.PDF" class="download-form__sended">
            <div class="download-form__sended__title">Спасибо за вашу заявку!</div>
            <div class="download-form__close" @click="close"></div>
            <div class="download-form__sended__text">
                <span v-if="secondsLeft">Через {{ secondsLeft }} секунд вы будете перенаправлены на страницу где сможете посмотреть и скачать подробную презентацию по туру.</span>

                Мы также свяжем вас с организатором путешествия. Он сможет предоставить детальную информацию по туру и ответить на любые вопросы. 

                Если вы не были переадресованы на страницу презентации, пожалуйста, <a @click="downloadProgram">нажмите здесь</a>.

                Команда GetAdventutres</div>
            <BaseButton
                class="button-fill"
                style="width:300px"
                @click="close"
            >Хорошо</BaseButton>
        </div>
        <div v-if="formSended && !trip.PDF" class="download-form__sended">
            <div class="download-form__sended__title">Спасибо за ваш запрос!</div>
            <div class="download-form__close" @click="close"></div>
            <div class="download-form__sended__text">
                Мы передали его организатору тура. В ближайшее время эксперт свяжется с Вами и направит подробную презентацию путешествия.

                Мы всегда на связи, безумно любим подбирать путешествия и всегда здесь  чтобы помочь!

                Команда GetAdventutres</div>
            <BaseButton
                class="button-fill"
                style="width:300px"
                @click="close"
            >Хорошо</BaseButton>
        </div>
    </div>
</template>

<script>
    import store from '@/store';
    import moment from '@/plugins/moment';
    import { mapState } from 'vuex';
    import { isMobile } from '@/helpers';
    import { ValidationProvider, ValidationObserver } from 'vee-validate';

    import BaseButton from '../../components/common/BaseButton.vue';
    import BaseTextField from '../../components/common/BaseTextField.vue';
    import BaseTextArea from '../../components/common/BaseTextArea.vue';
    import BasePhoneNumberInput from '../../components/common/BasePhoneInput.vue';

    export default {
        name: 'DownloadForm',
        components: {
            ValidationProvider,
            ValidationObserver,
            BaseButton,
            BaseTextField,
            BaseTextArea,
            BasePhoneNumberInput
        },
        data: () => ({
            feedback: {
                name: '',
                phone: '',
                email: '',
                comment: 'Получить программу тура',
                link: '',
                personalDataAgree: true,
            },
            phoneWithCode: '',
            formSended: false,
            secondsLeft: 0,
            intervalId: undefined,
        }),
        props: {
            text: ''
        },
        computed: {
            ...mapState('trips', {
                trip: state => state.entity
            }),
        },
        async mounted() {
        },
        methods: {
            async save() {
                this.feedback.link = document.location.href;
                const valid = await this.$refs.validator.validate()
                if(valid) {
                    this.feedback.phone = this.phoneWithCode;
                    this.$emit('save', this.feedback);
                    await this.formReverse()
                    await this.clear()
                    this.startTimer(async () => {
                        this.$emit('secondDownload')
                    });
                }
            },
            downloadProgram() {
                this.$emit('secondDownload')
            },
            startTimer(callback) {
                if (this.intervalId) {
                    clearInterval(this.intervalId);
                }

                this.secondsLeft = 10;

                this.intervalId = setInterval(() => {
                    if (this.secondsLeft > 0) {
                        this.secondsLeft -= 1;
                    } else {
                        clearInterval(this.intervalId);
                        callback()
                    }
                }, 1000);
            },
            clear() {
                this.feedback = {
                    name: '',
                    phone: '',
                    email: '',
                    comment: '',
                    link: '',
                }
            },
            async formReverse() {
                this.formSended = !this.formSended
            },
            close() {
                this.$emit('close')
                this.formSended = false
            }
        }
    }
</script>

<style lang="scss">
    .download-form {
        position: relative;
        max-width: 600px;
        width: 100%;
        padding: 50px;
        background-color: #fff;
        font-family: 'Inter';
        font-style: normal;
        border-radius: 12px;
        @media all and (max-width: 768px) {
            padding: 36px 20px;
        }
        &__page-name {
            font-weight: 600;
            font-size: 28px;
            line-height: 130%;
            letter-spacing: -0.02em;
            color: #273155;
            margin-bottom: 30px;
        }
        &__save {
            width: 100%;
        }
        &__personal-info {
            font-family: 'Gilroy';
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 150%;
            color: #808080;
            margin-top: 12px;
            margin-left: 5px;
        }
        &__close {
            position: absolute;
            top: 25px;
            right: 25px;
            width: 30px;
            height: 30px;
            background: url(../../assets/PlusGrey.svg);
            background-size: contain;
            transform: rotate(45deg);
            cursor: pointer;
        }
        &__sended {
            display: flex;
            flex-direction: column;
            align-items: center;
            &__title {
                font-weight: 600;
                font-size: 22px;
                line-height: 27px;
                color: #000000;
                margin-bottom: 12px;
            }
            &__text {
                font-weight: 400;
                font-size: 16px;
                line-height: 140%;
                text-align: center;
                letter-spacing: -0.02em;
                color: #A9AEB2;
                margin-bottom: 25px;
                // max-width: 300px;
            }
        }
    }
</style>
